import React from "react"
import Footer from "../../../../components/Footer/Footer";
import SupportLeftNav from "../../../../components/TwoColumnLeftNav/SupportLeftNav";
import SoftwareDownloadButton from "../../../../components/SoftwareDownload/SoftwareDownloadButton";
import {LocaleContext} from "../../../../components/layout";

const FourChLogger = () => {
    const {locale} = React.useContext(LocaleContext)
    const FourChLoggerJa = () => {
        return(
            <div className="content-container">
                <div className="software-content">
                    <h2 className={'large bold'}>4ch温度ロガー SK-L400T用 ソフトウェアダウンロード</h2>

                    <div className="terms-of-service-container">
                        <div className="content-pre">
                            <p className="equal-line">＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝</p>
                            <p>このドキュメントは本製品に関する重要な情報について書かれています。<br/>
                                ご使用の前に、必ずご一読くださるようお願いいたします。</p>
                            <p className="equal-line">＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝</p>
                        </div>
                        <div className="content-title">
                            <p>＜利用規約＞</p>
                        </div>
                        <div className="content-disclaimer">
                            <div className="content-disclaimer-title">
                                <p> ■免責事項</p>
                            </div>
                            <div className="content-disclaimer-content">
                                <p>
                                    株式会社佐藤計量器製作所は本製品に関して動作確認はおこなっておりますが、全ての状況下において動作を保証しているわけではありません。<br/>
                                    本製品により生じた損害は、直接的および間接的損害を問わず、弊社に一切の賠償責任はないものとします。<br/>
                                    操作上のミス、注意を無視した操作、ご使用になられているＰＣに起因する不具合に対して、弊社は責任を負いかねますのでご了承ください。<br/><br/>
                                    ※こちらで提供いたしております各種ソフトウェアは、日本国内で販売されている各製品と日本語版OSの組み合わせのみ、動作確認をおこなっております。<br/>
                                    海外版OSとの組み合わせにて発生したトラブルに関しましては、一切保証およびサポートいたしかねますのでご了承ください。
                                </p>
                            </div>
                        </div>
                        <div className="content-copyright">
                            <div className="content-copyright-title">
                                <p> ■著作権</p>
                            </div>
                            <div className="content-copyright-content">
                                <ol>
                                    <li>
                                        「4ch温度ロガー for Windows」のプログラムおよび関連ドキュメントに関する著作権は株式会社佐藤計量器製作所に帰属します。
                                    </li><br/>
                                    <li>
                                        「4ch温度ロガー for Windows」は以下条件をもとに、無償でご利用いただけます。
                                        <ul>
                                            <li>
                                                <p>逆コンパイル・ファイル解析、改変等を禁じます。</p>
                                            </li>
                                            <li>
                                                <p>本プログラムの使用による損害は、弊社は一切の責任を負いません。</p>
                                            </li>
                                            <li>
                                                <p>再配布は自由としますが、企業内・企業間、その他関係においても営利目的にしないこと。<br/>
                                                    再配布したプログラムの使用による損害に対しても弊社は一切の責任を負いません。</p>
                                            </li>
                                        </ul>
                                    </li>
                                </ol>
                                <p>本利用規約に同意いただけた方にのみ、以下ソフトウェアをご利用いただけます。</p>
                            </div>
                        </div>
                        <div className="content-feature">
                            <div className="content-feature-title">
                                <p> ■ソフトウェアの特徴</p>
                            </div>
                            <div className="content-feature-content">
                                <ol>
                                    <li>
                                        <p>4ch温度ロガー for Windows</p>
                                        <p>「4ch温度ロガー for Windows」は、4ch温度ロガーSK-L400Tのデータ解析をおこなうものです。SK-L400Tで測定したデータは、製品付属のSDカード でパソコンへ回収し、「4ch温度ロガー for Windows」を用いてグラフ表示・印刷およびデータ解析がおこなえます。また、データをCSV形式のファイルとして保存した場合は、市販のCSV形式対応の表計算ソフトでデータ解析が可能です。</p>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className="way-to-install">
                        <div className="install-title">
                            <p className="bold">【インストール方法】</p>
                        </div>
                        <div className="install-content">
                            <p>
                                「<a href="https://d3hdfc67hsdxgb.cloudfront.net/corporate/pdfs/4chJ_installmanual.pdf">4ch温度ロガーソフトウェアインストールマニュアル.pdf</a>」に従ってインストールをおこなってください。
                            </p>
                            <p className="important-text">(インストール前に必ずお読みください。)</p>
                            <p>
                                ご注意：
                                <br/>
                                旧バージョンのソフトウェアがインストール済みの場合、ダウンロードしたソフトウェアをインストールする前に、必ず旧バージョンのアンインストールをおこなってください。
                            </p>
                        </div>
                    </div>
                    <div className="download">
                        <div className="download-title">
                            <p className="bold">【ダウンロード】</p>
                        </div>
                        <div className="download-pre">
                            <p>※ご使用されるパソコンのOSバージョンおよび、bit数をご確認の上、対応するソフトウェアをダウンロードしてください。</p>
                        </div>
                        <div className="download-content">
                            <table>
                                <tbody>
                                <tr>
                                    <th>
                                        <p className="download-table-title">ソフトウェア名</p>
                                    </th>
                                    <td>
                                        <p>4ch温度ロガー for Windows Ver.1.1</p>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <p className="download-table-title">対象機器</p>
                                    </th>
                                    <td>
                                        <p>SK-L400T</p>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <p className="download-table-title">対象OS</p>
                                    </th>
                                    <td>
                                        <p>
                                            Windows XP
                                            <br/>
                                            Windows7
                                            <br/>
                                            Windows8
                                            <br/>
                                            Windows8.1
                                            <br/>
                                            Windows10
                                        </p>
                                        <p>
                                            64bit/32bit版
                                            <br/>
                                            ※動作検証はProfessional 64bit及び、32bit版にておこなっています。
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <p className="download-table-title">ダウンロード</p>
                                    </th>
                                    <td>
                                        <SoftwareDownloadButton
                                            yourKey64bit="corporate/software/4chLogger11J_64.zip"
                                            tmpKey64bit="software/4chLogger11J_64.zip"
                                            yourKey32bit="corporate/software/4chLogger11J_32.zip"
                                            tmpKey32bit="software/4chLogger11J_32.zip"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <p className="download-table-title">バージョンアップ内容</p>
                                    </th>
                                    <td>
                                        <p>Windows 8、Windows 8.1、Windows 10に対応しました。</p>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const FourChLoggerEn = () => {
        return(
            <div className="content-container">
                <div className="software-content">
                    <h2 className={'large bold'}>Software for 4-ch Datalogger Model SK-L400T</h2>

                    <div className="terms-of-service-container">
                        <div className="content-pre">
                            <p className="equal-line">＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝</p>
                            <p>This document describes important information about this product.<br/>
                                Read it thoroughly before use.</p>
                            <p className="equal-line">＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝</p>
                        </div>
                        <div className="content-disclaimer">
                            <div className="content-disclaimer-title">
                                <p className="bold">Software Use Agreements</p>
                            </div>
                            <div className="content-disclaimer-content">
                                <p>
                                    This software shall be used only by users who have agreed on the following terms:
                                </p>
                                <p>
                                    Exemption from liability<br/>
                                    We, Sato Keiryoki Mfg. Co., Ltd., check for general operation performance of the program, however we do not guarantee for those under all possible situations.<br/>
                                    We do not take any responsibilities on any damages caused directly or indirectly by this program.<br/>
                                </p>
                                <p>
                                    NB. The operation of our software has been checked by a combination of the Japanese edition OS and the device sold in Japan. We shall not guarantee nor support the problems that occurred during the usage of device and/or OS of overseas model (edition).
                                </p>
                            </div>
                        </div>
                        <div className="content-copyright">
                            <div className="content-copyright-title">
                                <p className="bold">Copyright</p>
                            </div>
                            <div className="content-copyright-content">
                                <ol>
                                    <li>
                                        The copyright of the program "4ch DATALOGGER for Windows" and related documents belongs to Sato Keiryoki Mfg. Co., Ltd.
                                    </li><br/>
                                    <li>
                                        The program "4ch DATALOGGER for Windows" can be used at free of charge under the following conditions:
                                        <ul>
                                            <li>
                                                <p>Any decompilation, file analysis or alteration is prohibited.</p>
                                            </li>
                                            <li>
                                                <p>We shall not be responsible for any damage resulting from the use of this program.</p>
                                            </li>
                                            <li>
                                                <p>The responsibilities related to redistribution of the program are entirely onto users; in case of redistribution, the program shall not be used for commercial reasons even when used in-corporation, inter-corporation or by others.<br/>
                                                    We shall not be responsible for any damage resulting from the use of the redistributed program.</p>
                                            </li>
                                        </ul>
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <div className="content-feature">
                            <div className="content-feature-title">
                                <p className="bold">Features</p>
                            </div>
                            <div className="content-feature-content">
                                <ol>
                                    <li>
                                        <p>4ch DATALOGGER for Windows</p>
                                        <p>"4ch DATALOGGER for Windows" is used to analyze the data measured by 4-ch Datalogger Model SK-L400T. It is possible to log the measured values on the SD card attached and download the data to a personal computer. By using "4ch DATALOGGER for Windows", graph plotting and data analysis can be done. The data saved in CSV format can be also evaluated by using commercial spreadsheet software supporting the "CSV" extension.</p>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className="way-to-install">
                        <div className="install-title">
                            <p className="bold">Installation Method</p>
                        </div>
                        <div className="install-content">
                            <p>
                                Install "4ch DATALOGGER for Windows Ver.1.1E" following "<a href="https://d3hdfc67hsdxgb.cloudfront.net/corporate/pdfs/4ch_installmanual.pdf">4ch_installmanual.pdf</a>".
                            </p>
                            <p className="important-text">(Be sure to read before installation)</p>
                        </div>
                    </div>
                    <div className="download">
                        <div className="download-title">
                            <p className="bold">Software Download</p>
                        </div>
                        <div className="download-pre">
                            <p>* Be sure to check the OS version and the bit number of your computer before starting the installation and download the applicable software.</p>
                        </div>
                        <div className="download-content">
                            <table>
                                <tbody>
                                <tr>
                                    <th>
                                        <p className="download-table-title">Program Name</p>
                                    </th>
                                    <td>
                                        <p>4ch DATALOGGER for Windows Ver.1.1E</p>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <p className="download-table-title">Model</p>
                                    </th>
                                    <td>
                                        <p>SK-L400T</p>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <p className="download-table-title">OS</p>
                                    </th>
                                    <td>
                                        <p>
                                            Windows XP
                                            <br/>
                                            Windows 7  （64/32bit）
                                            <br/>
                                            Windows 8  （64/32bit）
                                            <br/>
                                            Windows 8.1（64/32bit）
                                            <br/>
                                            Windows 10 （64/32bit）
                                        </p>
                                        <p>
                                            * Operation verification has been done by Windows 7, Windows 8, Windows 8.1, Windows 10 Professional 64-bit and 32-bit editions.
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <p className="download-table-title">Download</p>
                                    </th>
                                    <td>
                                        <SoftwareDownloadButton
                                            yourKey64bit="corporate/software/4chLogger11_64.zip"
                                            tmpKey64bit="software/4chLogger11_64.zip"
                                            yourKey32bit="corporate/software/4chLogger11_32.zip"
                                            tmpKey32bit="software/4chLogger11_32.zip"
                                        />
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <>
            <article className="company-info two-column-left-nav">
                <SupportLeftNav/>
                <section className="main-content">
                    {(locale === 'ja')?
                        <FourChLoggerJa/>:
                        <FourChLoggerEn/>
                    }
                </section>
            </article>
            <Footer/>
        </>
    )
}

export default FourChLogger
